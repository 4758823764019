import { axiosInstance } from '@/plugins/axios'

export class EventService {
  /**
   * @param {AxiosInstance} axios
   * */

  constructor (axios) {
    this.axios = axios
  }

  rejectEvent (payload) {
    return this.axios.post('/event/admin/event/reject', {
      eventId: payload.eventId,
      feedback: payload.feedback ?? 'Not-Applicable'
    })
  }

  acceptEvent (eventId) {
    return this.axios.post('/event/admin/event/accept', { eventId })
  }

  getEvents (currentPage, perPage, startDate, endDate, search, status) {
    return this.axios.get(`/event/admin/events?metadata=true&page=${
      currentPage
    }&size=${
      perPage
    }&related=itineraries${
      startDate ? `&fromdate=${startDate}` : ''
    }${endDate ? `&todate=${endDate}` : ''}${
      search ? `&search=${search}` : ''
    }${status ? `&status=${status}` : ''}`)
  }

  cancelRequest (eventId) {
    return this.axios.post('/event/cancel', {
      eventId
    })
  }

  queryRequest (payload) {
    return this.axios.post('/event/admin/event/query', {
      eventId: payload.eventId,
      feedback: payload.feedback ?? 'Not-Applicable'
    })
  }

  getSingleEvent (eventId) {
    return this.axios.get(`/event/admin/events/${eventId}`)
  }

  getAllAttendees (eventId, currentPage, perPage, startDate, endDate, search, status) {
    return this.axios.get(`/event/${eventId}/attendee?metadata=true&page=${
      currentPage
    }&size=${
      perPage
    }&related=itineraries${
      startDate ? `&fromdate=${startDate}` : ''
    }${endDate ? `&todate=${endDate}` : ''}${
      search ? `&search=${search}` : ''
    }${status ? `&status=${status}` : ''}`)
  }

  getAnAttendee (eventId) {
    return this.axios.get(`/event/attendees/${eventId}`)
  }

  notifyAttendees(eventId) {
    return this.axios.post(`/event/${eventId}/attendees/notify`)
  }

  notifySingleAttendee(attendeeId) {
    return this.axios.post(`/event/attendees/${attendeeId}/notify`)
  }

  // getUpcomingEvents(currentPage, perPage, startDate, endDate, search) {
  //   return this.axios.get(`/event/admin/events?${this.search ? '&search=' : ''}?limit=${
  //     perPage
  //   }&page=${
  //     currentPage
  //   }&metadata=true&sort[id]=desc&related=itineraries&status=accepted`)
  // }
  getRoutes(limit, search) {
    // https://api.shuttlers.ng/v1/routes?page=1&limit=16&metadata=true&related=&fields[route]=id,pickup,destination,city_id,pickup_geometry,destination_geometry,slug,overview_polyline,fare&include=total_bus_stops
    return this.axios.get(
      `/v1/routes?page=1&limit=${limit}${search ? '&search=' + search : ''}&metadata=true&related=&fields[route]=id,pickup,destination,city_id,pickup_geometry,destination_geometry,slug,overview_polyline`
    )

    // return this.axios.get(`/event/events/${eventId}/routes`)
  }

  getEventRoutes (eventId) {
    return this.axios.get(`/event/events/${eventId}/routes`)
  }

  assignRoutes (eventId, payload) {
    return this.axios.post(`/event/events/${eventId}/routes`, payload)
  }

  notifyOrganizer (eventId) {
    return this.axios.post(`/event/admin/events/${eventId}/notify-organizer`)
  }
}

const eventServiceInstance = new EventService(axiosInstance)
export default eventServiceInstance
