var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header justify-content-between"},[_c('div',{staticClass:"input-group input-group-flush d-flex flex-row-reverse"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.search),expression:"search",modifiers:{"trim":true}}],staticClass:"form-control list-search",attrs:{"type":"search","placeholder":"Search"},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing)return;_vm.search=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._m(0)]),_c('div',{})]),_c('div',{staticClass:"col px-0 d-flex my-3"},[_c('div',{staticClass:"col"}),_c('div',{staticClass:"col-auto"},[(_vm.events.length)?_c('div',{staticClass:"col-auto"},[_c('v-datepicker',{staticStyle:{"width":"100%"},attrs:{"placeholder":"Today","range":""},model:{value:(_vm.dateRangeFilter),callback:function ($$v) {_vm.dateRangeFilter=$$v},expression:"dateRangeFilter"}})],1):_vm._e()])]),_c('b-table',{attrs:{"striped":"","hover":"","selectable":"","responsive":"","items":_vm.events,"fields":_vm.fields,"current-page":_vm.currentPage,"busy":_vm.fetchingEvents},on:{"row-clicked":_vm.viewEvents},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"d-flex flex-column justify-content-center align-items-center"},[_c('div',{staticClass:"spinner-border",attrs:{"role":"status"}}),_c('p',{staticClass:"text-center mt-2"},[_c('strong',[_vm._v("Loading...")])])])]},proxy:true},{key:"empty",fn:function(){return [_c('p',{staticClass:"text-center text-secondary"},[_vm._v("No records available")])]},proxy:true},{key:"cell(title)",fn:function(data){return [_c('p',{staticClass:"my-0",staticStyle:{"line-height":"1"}},[_c('router-link',{staticClass:"sh-page-link text-nowrap",attrs:{"to":{
								name: 'ShowEventInfo',
								params: { eventId: data.item.id },
							}}},[_vm._v(_vm._s(data.item.title))])],1)]}},{key:"cell(user)",fn:function(data){return [_c('div',[_c('p',{staticClass:"font-weight-bold my-3",staticStyle:{"line-height":"1"}},[_c('router-link',{staticClass:"sh-page-link d-flex",attrs:{"to":{
									name: 'ShowEventInfo',
									params: { eventId: data.item.id },
								}}},[_c('span',[_vm._v(_vm._s(`${data.item.firstName} ${data.item.lastName}`))])])],1),_c('p',{staticClass:"my-3",staticStyle:{"line-height":"1"}},[_vm._v(" "+_vm._s(data.item.email)+" ")])])]}},{key:"cell(createdAt)",fn:function(data){return [_c('p',{staticClass:"text-nowrap my-0",staticStyle:{"line-height":"1"}},[_vm._v(" "+_vm._s(_vm.formatDate(data.item.createdAt))+" ")])]}},{key:"cell(trip_type)",fn:function(data){return [_c('p',{staticClass:"text-nowrap my-0",staticStyle:{"line-height":"1"}},[_vm._v(" "+_vm._s(data.item.returnTrip ? 'Return' : 'One-way')+" ")])]}},{key:"cell(attendeesEstimate)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.attendeesEstimate)+" ")]}},{key:"cell(location)",fn:function(data){return [_c('div',{staticClass:"route__destination locate"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/img/destination_point.svg")}}),_c('p',{staticClass:"my-0"},[_vm._v(_vm._s(data.item.location))])])]}},{key:"cell(status)",fn:function(data){return [(data.item.status === 'accepted')?_c('div',{staticClass:"status status-accepted"},[_vm._v(" "+_vm._s(data.item.status)+" ")]):_vm._e()]}}])})],1)]),(!_vm.fetchingEvents)?_c('div',{staticClass:"pageFooter"},[_c('section',[_c('p',{staticClass:"desc"},[_vm._v("Showing "+_vm._s(_vm.paginationStartIndex)+" - "+_vm._s(_vm.paginationEndIndex)+" of "+_vm._s(_vm.totalRecords))])]),_c('section',[_c('b-pagination',{attrs:{"total-rows":_vm.totalRecords,"per-page":_vm.perPage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"input-group-text border-0"},[_c('i',{staticClass:"fe fe-search"})])
}]

export { render, staticRenderFns }